import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Hortenzijas",
            image: "https://i.imgur.com/Egm96C2b.jpg",
            native: "Āzija",
            description: "Skaisti ziedi, bet cimperlīgi vāzē, jo mīl tīru ūdeni, tāpēc vāzes ūdeni ieteicams mainīt katru otro dienu. Labākā kopšana ir pirms likšanas vāzē nogriezt to 45 grādu leņķī, paturēt 30 sekundes karstā ūdenī un likt vāzē, kurā ir ūdens istabas temperatūrā. Ja hortenzija sāk vīst – apsmidzini to ar ūdeni vai iegremdē to ūdenī ar visu ziedgalvu. Hortenzija ir vienīgais grieztais zieds, kas dzer arī caur ziediem.",
        },
        {
            title: "Īriss",
            image: "https://i.imgur.com/sX8hXyCb.jpg",
            native: "Eiropa",
            description: "Vai zināji, ka senie Romieši slaveno pilsētu Florenci (kas nozīmē “Ziedošā”) tā nodēvēja, jo ap pilsētas teritoriju apkārt auga daudz īrisu ziedu? Un kopumā īrisus kā ziedus kultivē jau vairāk kā 2 tūkstošus gadus, tomēr vēl interesantāk, ir ka īrisi ne tikai skaisti izskatās, bet arī smaržo to ziedi un tiem ir aromātiskas saknes, kuru izvilkumu izmanto eksklusīviem parfimērijas izstrādājumiem, kā piemēram, Fragonard.",
        },
        {
            title: "Pulsatilla jeb meža silpurene",
            image: "https://i.imgur.com/Nl29irnb.jpg",
            native: "Eiropa",
            description: "Šie augi ir sastopami arī Latvijā, to košās krāsas un skaistuma dēļ, tos nesaudzīgi plūc cilvēki, kā rezultātā šis augs ir nonācis Latvijas aizsargājamo augu sarakstā (sarkanajā grāmatā). Šos augus arī izmanto homeopātijā. Šie ziedi ir kaironoši, pēc to izmantošanas, ieteicams rūpīgi nomazgāt rokas.",
        },
        {
            title: "Lefkoja jeb matiolas",
            image: "https://i.imgur.com/4l4p5ibb.jpg",
            native: "Vidusjūras apgabals",
            description: "Šoreiz Tev piegādājām salīdzinoši maz izziedējušas lefkojas, lai tās Tev vāzē stāvētu vēl ilgāk.",
        },
        {
            title: "Plīvurpuķe jeb ģipsene",
            image: "https://i.imgur.com/zqKob2gb.jpg",
            description: "To mēdz dēvēt par kaprīzo skaistuli no vecmāmiņas dārza. Tas ir cimperlīgs augs, ja vēlies to stādīt dārzā, bet kad tas uzzied, tad dārzs izskatās, kā balta, krāšņa kupena.",
        },    
        {
            title: "Lizantes",
            image: "https://i.imgur.com/myXgGH6b.jpg",
            native: "Dienvidamerika",
            description: "Lizantes izceļas ar savu faktūru un vizuāli ļoti atsvaidzina ziedu kompozīciju. Šie ziedi vāzē var ilgt līdz pat 14 dienām.",
        },
        {
            title: "Neļķe",
            image: "https://i.imgur.com/Q91LSVnb.jpg",
            native: "Eiropa",
            care: "Noteikti nevajadzētu blakus neļķēm turēt augļus. Ja neļķes mājās tiek turētas 18 grādu temperatūrā, tās vāzē var stāvēt līdz pat 3 nedēļām.",
            description: "Angliskais nosaukums Carnation. Tās pēdējos gados ir nepamatoti atstātas ēnā. Carnation tika uzskatītas par valdzinājuma, atšķirības un mīlestības ziediem un, ja mēs kārtīgāk ieskatāmies zieda tekstūrā, tā ir tik delikāta, kompleksa un fascinējoša. Neļķe ļoti atsvaidzina un padara interesantu, un daudz dziļāku jebkuru ziedu kompozīciju."
        },

        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        },

    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/841d6c97-44a5-4fcf-be01-16e168885d0a/playlist.m3u8"
                        captions="/titles/015.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            {/* <h2 class="title is-3">Smaržu vilnis</h2> */}

                            <p>Ziedu kompozīcija ir maigi zilā krāsā, ko veido hortenzija, īrisi, lefkojas, puķuzirņi, plīvurpuķes. Šie visi ir skaisti ziedi, kas prasīs mazliet vairāk rūpes to uzturēšanā. It īpaši hortenzija, kuru, pat ja šķiet, ka tā sāk vīst, var viegli turpināt ziedēt, ja ļauj ziediem atdzīvoties ūdenī. Skaties <a href="https://www.youtube.com/watch?v=1fOHKSAa9GQ" target="_blank">šo video ar soļiem hortenziju atdzīvināšanā</a>.</p>
{/* 
                            <ul>
                            <li>Piemērota būs vidēja izmēra vāze ar vidēji platu kaklu, lai ziedu kompozīcija ir kompakta.</li>
                            <li>Lai vāzē visi ziedi nesagāztos vienā čupā, sāc ar zaļumiem. Ņemām garāko zaru čupiņu un izkārtojam pa vāzes aizmuguri un sāniem. Pārējo vari improvizēt.</li>
                            <li>SVARĪGI: Neliekam kātus taisni iekšā vāzē. Zaļumus un ziedus kartojam pēc diognālās metodes, tas ir - liekot ziedu/zaļumu vāzes kreisajā pusē, kāts vāzē ūdenī būs labajā. Varbūt vieglāk iedomāties tos pašus 45 grādus, tā arī liekam puķes vāzē. Kāpēc tā darām? Liekot pa diognāli ziedus un zarus - tie vāzē ūdenī izveidos tādu kā “ligzdu vai tīklu”, kas atbalstīs citus kātus un dēļ šiem atbalstiem iespējams vāzē izkārtot šādas lielas kompozīcijas un ziedi stāvēs izvēlētajās vietās.</li>
                            <li>Ja kāds zieds nenofiksējas vietā, kur vēlies, tad izmanto zaļumus kā papildus atbalstu, pret kuru vari “atstutēt” ziedu.</li>
                            </ul> */}

                            <ul>
                                <li>Īrisi un pulsatillas vēl nav izplaukuši, dod tiem nedaudz laika un varēsi vērot kā Tava kompozīcija vāzē izmainās.</li>
                                <li>SVARĪGI: Pēc pulsatilla jeb meža silpurenes izmantošanas, ieteicams rūpīgi nomazgāt rokas, jo ziedi ir kairinoši.</li>
                            </ul>

                            <p>Pirms sāc: </p>

                            <ul>
                                <li>Uzvāri ūdeni un ielej to traukā (krūzē, bļodā…), tam jābūt karstam. Ņem hortenzijas ziedus, nogriez 3cm 45 grādu leņķī to kātiem un ieliec to kātu galus traukā ar karsto ūdeni aptuveni 30 sekundes, tad uzreiz liec iekšā vāzē</li>
                                <li>Sagatavo vāzi, kuru pirms tam kārtīgi izmazgā, ielej ūdeni istabas temperatūrā un pievieno ziedu barību, 1 pulverītis uz 0,5l ūdens.</li>
                                <li>Lefkojas transportēšanas dēļ var izskatīties noliekušās. Tas nekas - atjauno griezuma vietu un ieliec tos vāzē ar ūdeni. Pēc dažām stundām tās atgūs savu formu.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba! Seko video, lai redzētu darba procesu. </p>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">dot atsauksmi</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/0u1E7Qlh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Seko šīm norādēm, lai ziedi saglabātos ilgāk.</p>

                            <ol>
                                <li>Atceries ūdenī vāzē mainīt katru otro dienu</li>
                                <li>Izņem ziedus un noskalo ziedu kātus</li>
                                <li>Izlej ūdenu un izmazgā vāzi tīru</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
